import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Datagrid,
  ArrayField,
  TextField,
  ReferenceField,
  FunctionField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  List,
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ResourceContextProvider,
  DateField,
  useEditController,
  DateInput,
  useRefresh,
  SimpleShowLayout,
  RichTextField,
  Show,
  useResourceContext,
} from 'react-admin';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Tabs, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import RichTextInput from 'ra-input-rich-text';
import getForms from '../api/forms';
import getParticipants from '../api/participants';
import { editIterationById, postIterations } from '../api/iterations';
import getTerminals from '../api/terminals';
import getStudyTypes from '../api/studyTypes';
import {
  CustomCreateToolbar,
  CustomListActions,
  CustomToolbar,
  CustomCreateIterationToolbar,
  CustomListActionAnswerExport,
} from './common';
import { requiredField } from './validations';
import { StudyIteration } from '../models/iterations';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const StudyList = (props: any) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Listado" {...a11yProps(0)} />
          <Tab label="Respuestas" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <List actions={<CustomListActions text="Crear estudio" />} {...props}>
          <Datagrid rowClick="edit">
            <TextField source="id" label="Id" />
            <TextField source="name" label="Nombre" />
            <ReferenceArrayField
              source="terminals"
              label="Terminales"
              reference="terminals"
            >
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="studyType.type" label="Tipo de estudio" />
          </Datagrid>
        </List>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <List bulkActionButtons={<CustomListActionAnswerExport />} actions={<></>} {...props}>
          <Datagrid rowClick="show">
            <TextField source="id" label="Id" />
            <TextField source="name" label="Nombre" />
            <ReferenceArrayField
              source="terminals"
              label="Terminales"
              reference="terminals"
            >
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="answerCount" label="Cant Respuestas" />

            <ReferenceArrayField
              source="iterations"
              label="Iteraciones"
              reference="iterations"
            >
              <SingleFieldList>
                <ChipField source="fromDate" />
              </SingleFieldList>
            </ReferenceArrayField>

            <TextField source="studyType.type" label="Tipo de estudio" />
          </Datagrid>
        </List>
      </CustomTabPanel>
    </>
  );
};

export const StudyEdit = (props: any) => {
  const controllerProps = useEditController(props);
  const studyId = controllerProps.record?.id;

  const [forms, setForms] = useState<any[]>([]);
  const [participants, setParticipants] = useState<any[]>([]);
  const [terminals, setTerminals] = useState<any[]>([]);
  const [studyTypes, setStudyTypes] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [iterationSelected, setIterationSelected] =
    useState<StudyIteration | null>(null);
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');

  const setParticipantsChoices = () => {
    getParticipants()
      .then((response) =>
        setParticipants(
          response.map((e: any, i: any) => {
            return {
              id: e.id,
              name: `${e.firstName.toString()}   ${e.lastName.toString()}`,
            };
          }),
        ),
      )
      .catch((err) => {
        console.error('failed to get Participants', err);
      });
  };

  const setFormsChoices = () => {
    getForms()
      .then((response) =>
        setForms(
          response.map((e: any) => {
            return { id: e.id, name: e.name };
          }),
        ),
      )
      .catch((err) => {
        console.error('failed to get Forms', err);
      });
  };

  const setIterminalsChoices = () => {
    getTerminals()
      .then((response) =>
        setTerminals(
          response.map((e: any) => {
            return { id: e.id, name: e.name };
          }),
        ),
      )
      .catch((err) => {
        console.error('failed to get Terminals', err);
      });
  };
  const setStudyTypesChoices = () => {
    getStudyTypes()
      .then((response) =>
        setStudyTypes(
          response.map((e: any) => {
            return { id: e.id, name: e.type };
          }),
        ),
      )
      .catch((err) => {
        console.error('failed to get Study Types', err);
      });
  };

  useEffect(() => {
    setStudyTypesChoices();
    setFormsChoices();
    setParticipantsChoices();
    setIterminalsChoices();
  }, []);

  const refresh = useRefresh();

  const postIteration = async () => {
    setOpen(false);
    await postIterations({
      fromDate: from,
      toDate: to,
      idEstudio: studyId,
    });
    setFrom('');
    setTo('');
    refresh();
  };

  const editIteration = async () => {
    if (studyId && iterationSelected) {
      await editIterationById({
        ...iterationSelected,
        fromDate: from,
        toDate: to,
      });
      setFrom('');
      setTo('');
      setIterationSelected(null);
      setOpenEdit(false);
      refresh();
    }
  };

  const handleOpenEditIteration = (iteration: StudyIteration) => {
    setIterationSelected(iteration);
    setFrom(iteration.fromDate);
    setTo(iteration.toDate);
    setOpenEdit(true);
  };

  return (
    <>
      <Edit title="Editar estudio" {...props}>
        <SimpleForm toolbar={<CustomCreateToolbar />}>
          <TextField source="id" label="Id" />
          <TextInput source="name" label="Nombre" />
          <AutocompleteArrayInput
            label="Formularios"
            source="forms"
            choices={forms}
            validate={requiredField}
          />
          <AutocompleteArrayInput
            label="Participantes"
            source="participants"
            choices={participants}
            validate={requiredField}
          />
          <AutocompleteArrayInput
            label="Terminales"
            source="terminals"
            choices={terminals}
            validate={requiredField}
          />
          <RichTextInput
            source="conditions"
            label="Condiciones"
            validate={requiredField}
          />
          <RichTextInput
            source="introduction"
            label="Introduccion"
            validate={requiredField}
          />
          <AutocompleteInput
            source="studyTypeId"
            choices={studyTypes}
            label="Tipo de estudio"
            validate={requiredField}
          />
          <ResourceContextProvider value="iterations">
            <Modal open={open}>
              <Box sx={style}>
                <Box
                  position="absolute"
                  top="10px"
                  right="10px"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon />
                </Box>
                <Create title="Crear Iteración" {...props}>
                  <SimpleForm
                    toolbar={
                      <CustomCreateIterationToolbar
                        onSave={() => postIteration()}
                      />
                    }
                  >
                    <DateInput
                      source="fromDate"
                      label="Desde"
                      onChange={(value) => setFrom(value.target.value)}
                      validate={requiredField}
                    />
                    <DateInput
                      source="toDate"
                      label="Hasta"
                      onChange={(value) => setTo(value.target.value)}
                      validate={requiredField}
                    />
                  </SimpleForm>
                </Create>
              </Box>
            </Modal>
            <Modal open={openEdit}>
              <Box sx={style}>
                <Box
                  position="absolute"
                  top="10px"
                  right="10px"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setOpenEdit(false)}
                >
                  <CloseIcon />
                </Box>
                <Edit title="Editar Iteración" {...props}>
                  <SimpleForm
                    initialValues={{
                      fromDate: iterationSelected?.fromDate,
                      toDate: iterationSelected?.toDate,
                    }}
                    toolbar={
                      <CustomCreateIterationToolbar
                        onSave={() => editIteration()}
                      />
                    }
                  >
                    <DateInput
                      source="fromDate"
                      label="Desde"
                      onChange={(value) => setFrom(value.target.value)}
                      validate={requiredField}
                    />
                    <DateInput
                      source="toDate"
                      label="Hasta"
                      value={to}
                      onChange={(value) => setTo(value.target.value)}
                      validate={requiredField}
                    />
                  </SimpleForm>
                </Edit>
              </Box>
            </Modal>
            <List
              basePath="/iterations"
              actions={<CustomToolbar onClick={() => setOpen(true)} />}
              title="Iteraciones"
              filter={{ idEstudio: studyId }}
              empty={false}
            >
              <Datagrid>
                <DateField
                  options={{ timeZone: 'UTC' }}
                  locales="es-AR"
                  source="fromDate"
                  label="Desde"
                />
                <DateField
                  locales="es-AR"
                  options={{ timeZone: 'UTC' }}
                  source="toDate"
                  label="Hasta"
                />
                <FunctionField
                  label="Acciones"
                  render={(record: any) => (
                    <IconButton
                      aria-label="Editar"
                      onClick={(e) => {
                        handleOpenEditIteration(record);
                        e.stopPropagation();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                />
              </Datagrid>
            </List>
          </ResourceContextProvider>
          <ResourceContextProvider value="answers">
            <List
              basePath="/answers"
              title="Respuestas"
              filter={{ studyId }}
              empty={false}
            >
              <Datagrid rowClick="edit">
                <TextField source="id" />
                <ReferenceField source="formId" reference="forms">
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                  source="iteration"
                  label="Estudio"
                  reference="iterations"
                >
                  <ReferenceField source="studyId" reference="studies">
                    <TextField source="name" />
                  </ReferenceField>
                </ReferenceField>
                <ReferenceField source="userId" reference="participants">
                  <TextField source="firstName" />
                </ReferenceField>
                <DateField source="createdAt" />
                <ReferenceField
                  source="iteration"
                  label="Inicio Iter"
                  reference="iterations"
                >
                  <DateField source="fromDate" label="Desde" />
                </ReferenceField>
                <ReferenceField
                  source="iteration"
                  label="Fin Iter"
                  reference="iterations"
                >
                  <DateField source="toDate" label="Hasta" />
                </ReferenceField>
                <ArrayField source="answer" label="Cant Respuestas">
                  {/* <SingleFieldList> */}
                  <FunctionField
                    label="Cant Respuestas"
                    render={(record: any) => {
                      console.log(record);
                      return `${record.answer.length}`;
                    }}
                  />
                  {/* </SingleFieldList> */}
                </ArrayField>
              </Datagrid>
            </List>
          </ResourceContextProvider>
        </SimpleForm>
      </Edit>
    </>
  );
};

export const StudyPost = (props: any) => {
  const [forms, setForms] = useState<any[]>([]);
  const [participants, setParticipants] = useState<any[]>([]);
  const [terminals, setTerminals] = useState<any[]>([]);
  const [studyTypes, setStudyTypes] = useState<any[]>([]);

  const setParticipantsChoices = () => {
    getParticipants()
      .then((response) =>
        setParticipants(
          response.map((e: any) => {
            if (e.firstName) {
              return {
                id: e.id,
                name: `${e.firstName.toString()}   ${e.lastName.toString()}`,
              };
            }
            return { id: e.id, name: 'santiago garcia' };
          }),
        ),
      )
      .catch((err) => {
        console.error('failed to get Participants', err);
      });
  };

  const setFormsChoices = () => {
    getForms()
      .then((response) =>
        setForms(
          response.map((e: any) => {
            return { id: e.id, name: e.name };
          }),
        ),
      )
      .catch((err) => {
        console.error('failed to get Forms', err);
      });
  };

  const setIterminalsChoices = () => {
    getTerminals()
      .then((response) =>
        setTerminals(
          response.map((e: any) => {
            return { id: e.id, name: e.name };
          }),
        ),
      )
      .catch((err) => {
        console.error('failed to get Terminals', err);
      });
  };

  const setStudyTypesChoices = () => {
    getStudyTypes()
      .then((response) =>
        setStudyTypes(
          response.map((e: any) => {
            return { id: e.id, name: e.type };
          }),
        ),
      )
      .catch((err) => {
        console.error('failed to get Study Types', err);
      });
  };

  useEffect(() => {
    setStudyTypesChoices();
    setFormsChoices();
    setParticipantsChoices();
    setIterminalsChoices();
  }, []);

  return (
    <>
      <Create title="Crear estudio" {...props}>
        <SimpleForm toolbar={<CustomCreateToolbar />}>
          <TextInput source="name" label="Nombre" validate={requiredField} />
          <RichTextInput
            source="conditions"
            label="Condiciones"
            validate={requiredField}
          />
          <RichTextInput
            source="introduction"
            label="Introduccion"
            validate={requiredField}
          />
          <AutocompleteInput
            source="studyTypeId"
            choices={studyTypes}
            label="Tipo de estudio"
            validate={requiredField}
          />
          <AutocompleteArrayInput
            label="Formularios"
            source="forms"
            choices={forms}
            validate={requiredField}
          />
          <AutocompleteArrayInput
            label="Participantes"
            source="participants"
            choices={participants}
            validate={requiredField}
          />
          <AutocompleteArrayInput
            label="Terminales"
            source="terminals"
            choices={terminals}
            validate={requiredField}
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export const StudyShow = (props: any) => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <>
      <Show {...props}>
        <>
          <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="teaser" />
            <RichTextField source="body" />
            <DateField label="Publication date" source="created_at" />
            <ArrayField source="answers" label="Cant Respuestas">
              {/* <SingleFieldList> */}
              <FunctionField
                label="Cant Respuestas"
                render={(record: any) => {
                  return `${record?.answers?.length}`;
                }}
              />
              {/* </SingleFieldList> */}
            </ArrayField>
          </SimpleShowLayout>
        </>
      </Show>
    </>
  );
};
