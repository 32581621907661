import axios from 'axios';


export default async function getStudyTypes(): Promise<any> {
    const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/study-types`,
    );
    return response.data
}

