import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  PasswordInput,
} from 'react-admin';
import { CustomListActions, CustomCreateToolbar } from './common';
import { requiredField } from './validations';

export const ParticipantList = (props: any) => (
  <List actions={<CustomListActions text="Crear participante" />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Id" />
      <TextField source="identifier" label="Identificador" />
      <TextField source="firstName" label="Nombre" />
      <TextField source="lastName" label="Apellido" />
      <TextField source="documentNumber" label="Numero de documento" />
    </Datagrid>
  </List>
);

export const ParticipantEdit = (props: any) => (
  <Edit title="Editar participante" {...props}>
    <SimpleForm toolbar={<CustomCreateToolbar />}>
      <TextField source="id" label="Id" />
      <TextInput source="firstName" label="Nombre" validate={requiredField} />
      <TextInput source="lastName" label="Apellido" validate={requiredField} />
      <TextInput
        source="documentNumber"
        label="Numero de documento"
        validate={requiredField}
      />
      <TextInput
        source="identifier"
        label="Identificador"
        validate={requiredField}
      />
      <PasswordInput
        source="password"
        label="Contraseña"
        validate={requiredField}
      />
    </SimpleForm>
  </Edit>
);

export const ParticipantPost = (props: any) => (
  <Create title="Crear participante" {...props}>
    <SimpleForm toolbar={<CustomCreateToolbar />}>
      <TextInput source="firstName" label="Nombre" validate={requiredField} />
      <TextInput source="lastName" label="Apellido" validate={requiredField} />
      <TextInput
        source="documentNumber"
        label="Numero de documento"
        validate={requiredField}
      />
      <TextInput
        source="identifier"
        label="Identificador"
        validate={requiredField}
      />
      <TextInput
        source="password"
        label="Contraseña"
        validate={requiredField}
      />
    </SimpleForm>
  </Create>
);
