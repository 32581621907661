import React from 'react';
import {
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  List,
  DateInput,
  DateField,
  ListActions,
} from 'react-admin';
import { CustomCreateToolbar } from './common';

export const IterationList = (props: any) => (
  <List actions={<ListActions />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Id" />
      <TextField source="idEstudio" label="Estudio" />
      <DateField source="fromDate" label="Desde" />
      <DateField source="toDate" label="Hasta" />
    </Datagrid>
  </List>
);

export const IterationEdit = (props: any) => (
  <Edit title="Editar Iteración" {...props}>
    <SimpleForm toolbar={<CustomCreateToolbar />}>
      <TextField source="id" label="Id" />
      <TextField source="idEstudio" label="Estudio" />
      <DateInput source="fromDate" label="Desde" />
      <DateInput source="toDate" label="Hasta" />
    </SimpleForm>
  </Edit>
);

export const IterationPost = (props: any) => (
  <Create title="Crear Iteración" {...props}>
    <SimpleForm toolbar={<CustomCreateToolbar />}>
      <TextInput source="idEstudio" label="Estudio" />
      <DateInput source="fromDate" label="Desde" />
      <DateInput source="toDate" label="Hasta" />
    </SimpleForm>
  </Create>
);
