import * as React from 'react';
import {
  Toolbar,
  SaveButton,
  CreateButton,
  ExportButton,
  Button,
  useListContext,
} from 'react-admin';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import axios from 'axios';

export const CustomListActions = (props: any) => {
  const { text, create = true, exportButton = true } = props;
  return (
    <Toolbar
      style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      {...props}
    >
      {create && <CreateButton label={text} />}
      {exportButton && <ExportButton label="Exportar" />}
    </Toolbar>
  );
};

export const CustomListActionAnswerExport = (props: any) => {
  const { text, create = true, exportButton = true } = props;
  const { total, selectedIds } = useListContext();

  const downloadCSV = () => {
    axios({
      url: `https://api.cronolab.com.ar/files/${selectedIds[0]}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'answers.zip'); // or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };
  return <Button label="Generar CSV" onClick={() => downloadCSV()} />;
};

export const CustomCreateToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Guardar" />
    </Toolbar>
  );
};

export const CustomCreateIterationToolbar = (props: any) => {
  const { onSave } = props;
  return (
    <Toolbar {...props}>
      <SaveButton label="Guardar" handleSubmitWithRedirect={onSave} />
    </Toolbar>
  );
};

export const CustomToolbar = (props: any) => {
  const { onClick } = props;
  return (
    <Toolbar
      {...props}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignSelf: 'center',
      }}
    >
      <Typography variant="h6" component="div">
        Iteraciones
      </Typography>
      <Fab
        style={{ marginLeft: 20, marginBottom: 5 }}
        onClick={onClick}
        size="small"
        color="primary"
        aria-label="add"
      >
        <AddIcon />
      </Fab>
    </Toolbar>
  );
};
