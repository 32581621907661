import { Chip } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AnswerFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput label="Study" source="studyId" reference="studies" alwaysOn>
      <SelectInput optionText="name" value="id" />
    </ReferenceInput>
  </Filter>
);

export const AnswerList = (props: any) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Dashboard" {...a11yProps(0)} />
          <Tab label="Resultados" {...a11yProps(1)} />
          <Tab label="Listado" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <iframe
          title="Dashboard"
          src="https://metabase.incubator.com.ar/public/dashboard/cbb1787f-d6db-4971-bdee-6a3f77489d2c"
          frameBorder="0"
          width="100%"
          height="1200"
          allowTransparency
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <iframe
          title="Something"
          src="https://metabase.incubator.com.ar/public/dashboard/d80bba71-8f91-4c58-8888-5b0d26c62ced"
          frameBorder="0"
          height="1200"
          width="100%"
          allowTransparency
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <List {...props} filters={<AnswerFilter />} actions={{}}>
          <Datagrid rowClick="edit">
            <TextField source="id" />
            {/* <ReferenceField source="idForm" reference="forms"> */}
            <TextField source="formId" />
            {/* </ReferenceField> */}
            <ReferenceField source="studyId" reference="studies">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="userId" reference="participants">
              <TextField source="firstName" />
            </ReferenceField>
            <DateField source="createdAt" label="Fecha"/>
            <ReferenceField
              source="iteration"
              label="Inicio Iter"
              reference="iterations"
            >
              <DateField source="fromDate" label="Desde" />
            </ReferenceField>
            <ReferenceField
              source="iteration"
              label="Fin Iter"
              reference="iterations"
            >
              <DateField source="toDate" label="Hasta" />
            </ReferenceField>
          </Datagrid>
        </List>
      </CustomTabPanel>
    </>
  );
};
