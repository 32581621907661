import axios from 'axios';


export default async function getForms(): Promise<any> {
    const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/forms`,
    );
    return response.data
}

