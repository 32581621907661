import { Chip } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Dashboard = (props: any) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Dashboard" {...a11yProps(0)} />
          {/* <Tab label="Resultados" {...a11yProps(1)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <iframe
          src="https://metabase.incubator.com.ar/public/dashboard/1c136962-e0d9-43f9-b3c7-c15c2f7f5686"
          frameBorder="0"
          width="100%"
          height="1200"
          title='mainDashboard'
         />
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        <iframe
          title="Something"
          src="https://metabase.incubator.com.ar/public/dashboard/d80bba71-8f91-4c58-8888-5b0d26c62ced"
          frameBorder="0"
          height="1200"
          width="100%"
          allowTransparency
        />
      </CustomTabPanel> */}
    </>
  );
};

export default Dashboard;
