import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { CustomCreateToolbar, CustomListActions } from './common';

export const FormList = (props: any) => (
  <List
    bulkActionButtons={false}
    actions={<CustomListActions create={false} />}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Nombre" />
      <TextField source="introductoryText" label="Texto introductorio" />
      <TextField source="code" label="Código" />
    </Datagrid>
  </List>
);

export const FormEdit = (props: any) => (
  <Edit title="Editar formulario" {...props}>
    <SimpleForm toolbar={<CustomCreateToolbar />}>
      <TextField source="name" label="Nombre" />
      <TextField source="code" label="Código" />
      <RichTextInput
        source="introductoryText"
        label="Texto introductorio"
        validation={{ required: true }}
      />
      <RichTextInput
        source="finalText"
        label="Texto final"
        validation={{ required: true }}
      />
    </SimpleForm>
  </Edit>
);

export const FormPost = (props: any) => (
  <Create title="Crear formulario" {...props}>
    <SimpleForm>
      <TextInput source="idForm" />
      <TextInput source="name" />
      <TextField source="code" label="Código" />
      <RichTextInput
        source="introductoryText"
        validation={{ required: true }}
      />
      <RichTextInput source="finalText" validation={{ required: true }} />
      <TextInput source="iterations" />
    </SimpleForm>
  </Create>
);
