import axios from 'axios';
import { StudyIteration } from '../models/iterations';

export default async function getIterations(): Promise<any> {
  const response = await axios.get(
    `${process.env.REACT_APP_API_HOST}/iterations`,
  );
  return response.data;
}

export async function postIterations(payload: any): Promise<any> {
  const response = await axios.post(
    `${process.env.REACT_APP_API_HOST}/iterations`,
    payload,
  );
  return response.data;
}

export async function editIterationById(payload: StudyIteration): Promise<any> {
  const response = await axios.patch(
    `${process.env.REACT_APP_API_HOST}/iterations/${payload.id}`,
    payload,
  );
  return response.data;
}
