import React from 'react';
import './App.css';
import lb4Provider from 'react-admin-lb4';
import {
  Admin,
  Resource,
  fetchUtils,
  ListGuesser,
  ShowGuesser,
} from 'react-admin';
import GroupIcon from '@mui/icons-material/Group';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ComputerIcon from '@mui/icons-material/Computer';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ScienceIcon from '@mui/icons-material/Science';
import BiotechIcon from '@mui/icons-material/Biotech';
import RestoreIcon from '@mui/icons-material/Restore';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import spanishMessages from '@blackbox-vision/ra-language-spanish';
import authProvider from './authProvider';
import {
  ParticipantList,
  ParticipantEdit,
  ParticipantPost,
} from './components/participants';
import Dashboard from './components/dashboard';
import { FormList, FormEdit, FormPost } from './components/forms';
import { StudyPost, StudyList, StudyEdit, StudyShow } from './components/studies';
import {
  TerminalEdit,
  TerminalPost,
  TerminalList,
} from './components/terminals';
import {
  IterationList,
  IterationEdit,
  IterationPost,
} from './components/iterations';
import { AnswerList } from './components/answer';

const httpClient = (url: any, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' }); // no-param-reassign
  }
  const auth = localStorage.getItem('auth');
  if (auth) {
    const { token } = JSON.parse(auth);
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
  }
  return auth;
};

const dataProvider = lb4Provider(process.env.REACT_APP_API_HOST);

const i18nProvider = polyglotI18nProvider(() => spanishMessages);

const App: React.FC = () => {
  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
    >
      <Resource
        options={{ label: 'Dashboard' }}
        name="dashboard"
        list={Dashboard}
        icon={DashboardIcon}
      />
      <Resource
        options={{ label: 'Participantes' }}
        name="participants"
        edit={ParticipantEdit}
        list={ParticipantList}
        create={ParticipantPost}
        icon={GroupIcon}
      />
      <Resource
        options={{ label: 'Formularios' }}
        name="forms"
        edit={FormEdit}
        list={FormList}
        icon={FormatListBulletedIcon}
      />
      <Resource
        options={{ label: 'Estudios' }}
        icon={ScienceIcon}
        name="studies"
        edit={StudyEdit}
        list={StudyList}
        create={StudyPost}
        show={StudyShow}
      />
      <Resource
        options={{ label: 'Terminales' }}
        icon={ComputerIcon}
        name="terminals"
        edit={TerminalEdit}
        list={TerminalList}
        create={TerminalPost}
      />
      <Resource
        options={{ label: 'Answers' }}
        icon={BiotechIcon}
        name="answers"
        show={ShowGuesser}
        list={AnswerList}
      />
      <Resource
        options={{ label: 'Iteraciones' }}
        icon={RestoreIcon}
        name="iterations"
        edit={IterationEdit}
      />
    </Admin>
  );
};

export default App;
