import React from 'react';
import {
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  List,
  DateField,
} from 'react-admin';
import { CustomCreateToolbar, CustomListActions } from './common';
import { requiredField } from './validations';

export const TerminalList = (props: any) => (
  <List
    perPage={50}
    actions={<CustomListActions text="Crear terminal" />}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" label="Id" />
      <TextField source="name" label="Nombre" />
      <DateField
        source="lastSync"
        locales="es-AR"
        label="Ultima sicronización"
        emptyText='Sin datos'

      />
      <DateField
        source="lastAnswerSync"
        locales="es-AR"
        label="Ultima sicronización de respuestas"
        emptyText='Sin datos'

      />
    </Datagrid>
  </List>
);

export const TerminalEdit = (props: any) => (
  <Edit title="Editar Terminal" {...props}>
    <SimpleForm toolbar={<CustomCreateToolbar />}>
      <TextField source="id" label="Id" />
      <TextInput source="name" label="Nombre" validate={requiredField} />
      <DateField
        source="lastSync"
        locales="es-AR"
        label="Ultima sicronización"

      />
      <DateField
        source="lastAnswerSync"
        locales="es-AR"
        label="Ultima sicronización de respuestas"
        emptyText='Sin datos'
      />

    </SimpleForm>
  </Edit>
);

export const TerminalPost = (props: any) => (
  <Create title="Crear Terminal" {...props}>
    <SimpleForm toolbar={<CustomCreateToolbar />}>
      <TextInput source="name" label="Nombre" validate={requiredField} />
    </SimpleForm>
  </Create>
);
